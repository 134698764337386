import styled from 'styled-components'

export const Wrapper = styled.article`
  padding: 100px 20px;
  max-width: 1200px;
  margin: 0 auto;
  @media only screen and (max-width: 1023px) {
    padding: 60px 20px;
  }
`
