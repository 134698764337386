import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Divider, Wrapper } from './styles'

const Card = () => {
  return (
    <>
      <Wrapper>
        <StaticImage src={'../../../images/package.jpg'} alt="Auto Transwell" />
        <div className="content">
          <h4>Jak działa transport liniowy? – definicja</h4>
          <p>
            Jednak, aby dokładnie odpowiedzieć na powyższe pytanie, warto znać
            dokładny system działania kurierskiego transportu liniowego, który
            coraz częściej określa się słowem linehaul. Duże firmy kurierskie
            zwykle posiadają sortownie w każdym większym mieście. Poza nimi jest
            jeszcze oddział centralny lub kilka większych oddziałów w
            największych miastach regionu.
          </p>
          <p>
            Transport liniowy zaczyna się u kurierów, którzy odbierają paczki od
            klientów. Każdy kurier ma swój region, z którego zbiera zlecenia, a
            następnie zawozi je do najbliższej sortowni. Na sortowni najczęściej
            dzieli się paczki w zależności od miejsca, do którego mają zostać
            dostarczone, a następnie rozsyłane są do sortowni w odpowiednich
            regionach. Tam regionalni kurierzy rozwożą przesyłki pod wskazany
            adres.
          </p>
        </div>
      </Wrapper>
      <Divider />
      <Wrapper noMargin>
        <div className="content">
          <h4>Współpraca z Transwelli</h4>
          <p>
            Jest to skrócony opis tradycyjnego transportu liniowego. Na pierwszy
            rzut oka dany system przedstawia same plusy. Dzięki szerokiej gałęzi
            sortowni wraz z dużą liczbą kurierów można minimalizować koszty i
            maksymalizować efekty. Niestety jest to podejście na ilość, a nie na
            jakość. Tam pojedynczy klient się nie liczy, a jedynie liczby
            dostarczanych paczek. W Internecie jest wiele historii, opinii czy
            nagrań podejścia kurierów, którzy, mówiąc kolokwialnie, często mają
            wszystko gdzieś. Poza tym w grę wchodzi jeszcze problem związany z
            przeładunkami i istniejącymi zagrożeniami, że coś zostanie
            uszkodzone. Dodatkowo na sortowniach często zdarzają się wypadki i
            część towaru zostaje bezpowrotnie zniszczona. Trudno jest
            jednoznacznie odpowiedzieć na pytanie jaki transport liniowy wybrać.
            Jednak jeśli szukasz firmy, która łączy niewygórowaną ceną, szybki
            transport oraz daje gwarancję, że paczka zawsze trafi do Ciebie cała
            i bez żadnego uszkodzenia, koniecznie do nas zadzwoń.
          </p>
        </div>
        <div className="images">
          <StaticImage
            src={'../../../images/transwell_kato.jpg'}
            alt="Auto Transwell"
          />
          <StaticImage
            src={'../../../images/transwell_unpacking.jpg'}
            alt="Auto Transwell"
          />
        </div>
      </Wrapper>
      <Divider />
      <Wrapper>
        <StaticImage
          src={'../../../images/transwell_cars_tires.jpg'}
          alt="Auto Transwell"
        />
        <div className="content">
          <h4>Transport liniowy – jak robi to Transwell?</h4>
          <p>
            Bez wątpienia nie jesteśmy tak dużą firmą, jak te, które na co dzień
            dostarczają Ci paczki. Jednak czy jest to wada? Jedną z naszych
            największych zalet, którą zawsze wymieniają nasi klienci, jest to,
            że zawsze szanujemy naszych klientów, staramy się sprostać ich
            wymaganiom i potrzebom oraz zawsze dotrzymujemy terminów.
          </p>
          <p>
            Korzystając z naszej usługi, masz gwarancję, że kurier zajmujący się
            Twoim transportem, zrobi wszystko, abyś był zadowolony z usługi. Na
            bieżąco informujemy swoich klientów o przesyłce, o tym gdzie jest i
            kiedy dotrze na miejsce. Zawsze przed doręczeniem informujemy, że
            niebawem doręczymy paczkę. Jeśli nie ma Cię w domu, ale jesteś w
            pobliżu, bez żadnego problemu możemy dowieźć zamówienie pod nowy
            adres. Wybierając nas, poczujesz się, jakby wszystko było
            dopilnowane za Ciebie.
          </p>
          <p>
            Naszą kolejną zaletą, która dla niektórych może wydać się wadą, jest
            brak ogromnej gałęzi mniejszych i większych sortowni. Można
            pomyśleć, że przez to usługa może być wykonywana dłużej. Nie jest to
            prawdą. Posiadamy odpowiednio zoptymalizowaną logistykę transportu
            liniowego, która pozwala w jeszcze szybszym czasie dostarczyć
            przesyłki bez zbędnych przeładowywań między poszczególnymi punktami
            drogi.
          </p>
          <p>
            Jeśli szukasz firmy, która dostarczy Twoje przesyłki szybko i w
            rozsądnej cenie, zadba o Ciebie i Twoich klientów oraz, co
            najważniejsze, o towar, to koniecznie do nas zadzwoń, a na pewno się
            nie zawiedziesz!
          </p>
        </div>
      </Wrapper>
    </>
  )
}

export default Card
