import React from 'react'
import Article from '../Article'
import { Wrapper } from './styles'
import Card from './Card'

const LinearTransportPage = () => {
  return (
    <Article>
      <Wrapper>
        <p className="introduction">
          Definicja transportu liniowego określa połączenia między oddziałami
          firmy, sortowniami i siedzibą centralną. Rozwiązanie to gwarantuje
          szereg zalet. Przede wszystkim pozwala osiągnąć najlepsze rezultaty,
          jeśli chodzi o porównanie ceny do szybkości dostawy. Są to zwykle dwa
          najważniejsze czynniki, które decydują o wyborze danej firmy
          kurierskiej. Jednak nie jest tak zawsze. W wielu przypadkach od ceny i
          szybkości dostawy ważniejsze są jakość i profesjonalizm wykonywanej
          usługi. Poza tym liczy się jeszcze fakt, że w przypadku transportu
          liniowego dana przesyłka jest przeładowywana przynajmniej 3 razy, a w
          niektórych przypadkach nawet i 6 razy. Każdorazowe przeładowywanie
          towaru może spowodować jego uszkodzenie. Ponadto na typowych dużych
          sortowniach bardzo często dochodzi do wypadków i uszkodzeń towaru, a
          wiodące firmy kurierskie nie lubią wypłacać odszkodowań nieraz
          sięgających tysięcy złotych.
        </p>
        <p className="introduction">
          W tym momencie nasuwa się pytanie, jaki transport liniowy wybrać i czy
          w ogóle się na niego decydować.
        </p>
        <h3>
          Dostępność i gotowość 24h/365 dni w roku
          <br />
          <br />
          Zaangażowanie i indywidualne podejście do każdego klienta
        </h3>
        <p className="common">
          Oferujemy możliwość stałej współpracy z atrakcyjnymi rabatami dla
          firm.
        </p>
        <Card />
      </Wrapper>
    </Article>
  )
}

export default LinearTransportPage
