/* eslint-disable react/jsx-pascal-case */

import React from 'react'
import Layout from 'src/hoc/Layout'
import Nav from 'components/Nav'
import SubPageHeader from 'components/SubPageHeader'

import LinearTransportPage from 'components/LinearTransportPage'
import SEO from 'components/SEO'

const LinearTransport = props => {
  return (
    <Layout>
      <SEO />
      <Nav {...props} black />
      <SubPageHeader title="Transport wahadłowy" imageName="SHUTTLE" />
      <LinearTransportPage />
    </Layout>
  )
}

export default LinearTransport
