import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }

  .gatsby-image-wrapper {
    margin-top: 65px;
    @media only screen and (max-width: 1023px) {
      margin-top: 0px;
      margin-bottom: 20px;
    }
    div {
      background-color: transparent !important;
    }
  }

  .content {
    max-width: 45%;
    margin-left: ${({ noMargin }) => (noMargin ? 0 : '50px')};
    @media only screen and (max-width: 1023px) {
      max-width: 100%;
      margin: 0;
    }
    h4 {
      color: ${({ theme }) => theme.colors.blue};
      font-size: 26px;
      margin-bottom: 30px;
    }
    p {
      font-size: 18px;
      margin-bottom: 20px;
      line-height: 1.5;
      color: ${({ theme }) => theme.colors.darkGray};
      @media only screen and (max-width: 1023px) {
        font-size: 14px;
        text-align: justify;
      }
    }
  }
  .images {
    margin-left: 50px;
    @media only screen and (max-width: 1023px) {
      margin-left: 0;
      .gatsby-image-wrapper:nth-child(2) {
        display: none;
      }
    }
  }
`
export const Divider = styled.span`
  width: 100%;
  height: 1px;
  display: block;
  color: #cbcbcb;
  background-color: #cbcbcb;
  margin: 120px auto;
  @media only screen and (max-width: 1023px) {
    margin: 20px auto;
  }
`
